import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import { Section, Container, ProductsList } from "../components/global"
import { useStaticQuery, graphql } from "gatsby"

import HeroHeader from "../components/sections/hero-header"
import ContactForm from "../components/contact-form"

import backShape from "../images/back-shape-2.svg"

class ProductsOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCategory: "All",
    }
  }

  setSelectedCategory = (category) => {
    console.log(category)
    this.setState({ selectedCategory: category })
  }

  render() {
    const categories = ["All"]
    const selectedCategory = this.state.selectedCategory
    this.props.products.map(({ node }) => {
      if (categories.indexOf(node.category) === -1) {
        categories.push(node.category)
      }
      return node
    })
    const filtredProducts = this.props.products.filter(({ node }) => {
      return selectedCategory === "All" || node.category === selectedCategory
    })
    return (
      <>
        <ProductsFilterContainer>
          <ProductsFilterDescription>
            Filter products by category
          </ProductsFilterDescription>
          <ProductsFilter>
            {categories.map((category) => (
              <ProductsFilterOption>
                <FilterOptionLink
                  className={
                    this.state.selectedCategory === category ? "active" : ""
                  }
                  onClick={() => {
                    console.log(category)
                    this.setSelectedCategory(category)
                  }}
                  title={category}
                >
                  {category}
                </FilterOptionLink>
              </ProductsFilterOption>
            ))}
          </ProductsFilter>
        </ProductsFilterContainer>
        <ProductsList products={filtredProducts}></ProductsList>
      </>
    )
  }
}
/*
 */
const ProductsPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      products: allProductsJson(sort: { fields: [weight], order: DESC }) {
        edges {
          node {
            fields {
              slug
            }
            title
            description
            shortDescription
            category
            iconImage {
              publicURL
            }
          }
        }
      }
    }
  `)
  // and enhancing availability of the data
  return (
    <Layout
      title="Microsoft 365 tools to streamline your processes"
      description="Check out our automation tools and products for Office 365, Microsoft Teams, Power Apps, Power Automate and Microsoft Azure"
      location={props.location}
    >
      <HeroHeader
        title="Our products"
        subTitle="Amplify productivity and streamline processes
              by automating routine tasks"
        backgroundImage="products-back.png"
        foregroundImage="products-front.png"
        middleImage="middle-shape.png"
      />
      <Section type="secondary">
        <Container>
          <ProductsOverview products={data.products.edges}></ProductsOverview>
        </Container>
      </Section>
      <Section
        type="accent"
        style={{
          backgroundImage: `url(${backShape})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundSize: "cover",
        }}
      >
        <Container narrow="true" shadow="true">
          <ContactForm title="Do you have any question? We are here to answer!" />
        </Container>
      </Section>
    </Layout>
  )
}

export default ProductsPage

/*
const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;

  h1 {
    ${(props) => props.theme.font_size.larger};
  }

  h2 {
    ${(props) => props.theme.font_size.large};
  }
`*/

const ProductsFilterContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
`

const ProductsFilterDescription = styled.h3`
  ${(props) => props.theme.font_size.xsmall}
  margin-bottom: 15px;
`

const ProductsFilter = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ProductsFilterOption = styled.li`
  margin: 0 7px 10px;
`

const FilterOptionLink = styled.button`
  text-decoration: none;
  font-size: 1.125rem;
  padding: 1rem 1rem 0.675rem;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;

  ${(props) => props.theme.animation.regular};

  background-color: ${(props) => props.theme.color.white.regular};
  border-color: ${(props) => props.theme.color.white.darker};

  &:hover,
  &.active {
    color: ${(props) => props.theme.color.white.regular};
    background-color: ${(props) => props.theme.color.accent};
  }
`
